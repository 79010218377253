<template>
    <div class="card-front-holder" :style="containerStyle" ref="card">
        <div class="card-front-inner" @mouseover="onMouseOver" @mouseout="onMouseOut" @mousemove="onMouseMove" v-bind:style="cardStyle">
            <div v-if="card" class="card-front-content">
                <v-img class="image" :src="card.img" :max-height="width" :max-width="width" contain />
                <div class="name">{{ card.name }}</div>
                <div class="code">{{ card.code }}</div>
                <div class="overlay"></div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    components: {  },
    name: 'ContestCardFront',
    props: {
        width: {
            type: Number,
            default: 171
        },
        height: {
            type: Number,
            default: 270
        },
        img: {
            type: String,
            default: '/images/card-samsung-back.png'
        },
        zoom: {
            type: Boolean,
            default: false
        },
        card: {
            type: Object
        }
    },
    data: () => ({
        zoomed: false,
        perspective: 1000,
		rotateX: 0,
		rotateY: 0,
		rotateZ: 0,
		scale: 1.2,
        offsetX: 0,
        offsetY: 0
    }),
    computed: {
        containerStyle:  function () {
            if (!this.zoom || !this.zoomed) {
                return {}
            }
            else {
                return {perspective: this.perspective+'px'};
            }
		},
		cardStyle : function () {
            let style = {
                backgroundImage: `url('${this.img}')`,
                backgroundSize: 'cover',
                width: `${this.width}px`,
                height: `${this.height}px`
            }

            if (!this.zoom || !this.zoomed) {
                return style
            }
            else {
                return {
                    ...style,
                    transform: 'rotateX('+this.rotateX+'deg) rotateY('+this.rotateY+'deg) rotateZ('+this.rotateZ+'deg) scale('+this.scale+')',
                    zIndex: 50
                };
            }
		}    
    },
    methods: {
        onMouseOver: function(e) {
            this.zoomed = true;
            this.onMouseMove(e);
        },
        onMouseMove: function(e) {
            let width = this.$refs.card ? this.$refs.card.clientWidth : this.width;
            let height = this.$refs.card ? this.$refs.card.clientHeight : this.height;

            this.offsetX = e.offsetX;
            this.offsetY = e.offsetY;

            let distanceX = Math.abs(width - e.offsetX);
            let distanceY = Math.abs(height - e.offsetY);
            
            this.rotateY = (50-Math.round(100/width*distanceX))/-2;
            this.rotateX = (50-Math.round(100/height*distanceY))/2;
        },
        onMouseOut: function() {
            this.zoomed = false;
        }
    }
  }
</script>

<style scoped>
   .card-front-inner {
       z-index: 20;
       
       transition: 0.2s;
       margin: 0 auto;
   }
   .card-front-content {
       text-align: center;
       position: relative;
       height: 100%;
   }
   
   .card-front-content .name {
       padding: 0.5em;
       font-size: 1.2em;
       position: absolute;
       width: 100%;
       height: 18%;
       top: 0;
       text-shadow: 0 0 3px #FFFFFF;
   }
   .card-front-content .code {
       padding: 0.5em;
       font-size: 1.3em;
       position: absolute;
       width: 100%;
       height: 18%;
       bottom: 0;
       text-shadow: 0 0 3px #FFFFFF;
   }
   .card-front-content .image {
       margin: 0 0.5em;
       top: 18%;
   }
   .card-front-inner .overlay {
       position: absolute;
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       bottom: 0;
       right: 0;
   }
   
</style>